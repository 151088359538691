import { API, Auth } from "aws-amplify";

async function getUser() {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch {
    user = null;
  }
  return user;
}

export async function checkUserGroup(groupName) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { signInUserSession } = user;
    const { idToken } = signInUserSession;
    const { payload } = idToken;
    const userGroups = payload["cognito:groups"] || [];
    //console.log(payload)
    return userGroups.includes(groupName);
  } catch (error) {
    console.error("Error fetching user groups:", error);
    return false;
  }
}

export async function checkUserPages() {
  const pages = [];
  try {
    const user = await getUser();

    if (user != null) {
      const { signInUserSession } = user;
      const { idToken } = signInUserSession;
      const { payload } = idToken;
      const userGroups = payload["cognito:groups"] || [];

      // if (userGroups.includes("UploadTranscriptAccess")) {
      //   pages.push("File Upload");
      // }

      // NOTE: May eventually move Company to a separate permissions category
      // if (userGroups.includes("ViewTranscriptAccess")) {
      //   pages.push("Company");
      // }

      if (userGroups.includes("ViewTranscriptAccess")) {
        pages.push("Transcripts");
      }

      if (userGroups.includes("ViewAnalytics")) {
        pages.push("Analytics");
      }
      
    }

    return pages;
  } catch (error) {
    console.error("Error fetching user pages:", error);
    return pages;
  }
}

export async function checkUserSettings() {
  const settings = [];

  try {
    const user = await getUser();

    if (user != null) {
      //const { signInUserSession } = user;
      //const { idToken } = signInUserSession;
      //const { payload } = idToken;
      //const userGroups = payload["cognito:groups"] || [];

      // settings.push("Profile");
      // settings.push("Settings");

      // if (userGroups.includes("UploadTranscriptAccess")) {
      //   settings.push("File Upload");
      // }

      // if (userGroups.includes("ViewTranscriptAccess")) {
      //   settings.push("Transcripts");
      // }

      // if (userGroups.includes("Admin")) {
      //   settings.push("Admin");
      // }

      settings.push("Logout");
    }

    return settings;
  } catch (error) {
    console.error("Error fetching user settings:", error);
    return settings;
  }
}

export async function getUserFirstAndLastName() {
  let fullName = "Default User";

  try {
    const user = await getUser();

    if (user != null) {
      const { signInUserSession } = user;
      const { idToken } = signInUserSession;
      const { payload } = idToken;
      const familyName = payload["family_name"] || "User";
      const givenName = payload["given_name"] || "Default";
      fullName = givenName + " " + familyName;
    } else {
      fullName = "";
    }
    return fullName;
  } catch (error) {
    console.error("Error fetching user first and last name:", error);
    return false;
  }
}

export async function logUserAgreement() {
  try {
    const user = await Auth.currentAuthenticatedUser();

    if (!user) {
      throw new Error("User not authenticated");
    }

    const jwtToken = user.signInUserSession.idToken.jwtToken;

    const screenResolution = `${window.screen.width}x${window.screen.height}`;

    const body = {
      cognito_username: user.username,
      screen_resolution: screenResolution,
    };

    const request = {
      body,
      headers: {
        Authorization: jwtToken,
      },
    };

    return API.post("clientLoggingAPI", "/log-agreement", request)
      .then((response) => {
        console.log("REST API - user agreement (success):", response);
        return response;
      })
      .catch((error) => {
        console.error("REST API - user agreement (error):", error);
        return error;
      });
  } catch (error) {
    console.error("REST API - user agreement (error):", error);
  }
}

export async function logUserEvent(eventCategory, eventNotes = "") {
  try {
    const user = await Auth.currentAuthenticatedUser();

    if (!user) {
      throw new Error("User not authenticated");
    }

    const jwtToken = user.signInUserSession.idToken.jwtToken;

    const screenResolution = `${window.screen.width}x${window.screen.height}`;

    const body = {
      cognito_username: user.username,
      event_category: eventCategory,
      event_notes: eventNotes,
      screen_resolution: screenResolution,
    };

    const request = {
      body,
      headers: {
        Authorization: jwtToken,
      },
    };

    return API.post("clientLoggingAPI", "/log-event", request)
      .then((response) => {
        //console.log("REST API - user event (success):", response);
        return response;
      })
      .catch((error) => {
        //console.error("REST API - user event (error):", error);
        return error;
      });
  } catch (error) {
    //console.error("REST API - user event (error):", error);
  }
}

export async function getPowerBIReportAccessDetails(id) {
  try {

    const user = await Auth.currentAuthenticatedUser();

    if (!user) {
      throw new Error("User not authenticated");
    }

    const jwtToken = user.signInUserSession.idToken.jwtToken;

    const body = {
      cognito_username: user.username,
      id: id,
    };

    const request = {
      body,
      headers: {
        Authorization: jwtToken,
      },
    };

    return API.post("clientLoggingAPI", "/get-powerbi-report-access-details", request)
      .then((response) => {
          //console.log("REST API - user event (success):", response);
        return response;
      })
      .catch((error) => {
          //console.error("REST API - user event (error):", error);
        return error;
      });
  } catch (error) {
      //console.error("REST API - user event (error):", error);
  }
}


export async function checkUserAcceptance() {
  try {

    const user = await Auth.currentAuthenticatedUser();

    if (!user) {
      throw new Error("User not authenticated");
    }

    const jwtToken = user.signInUserSession.idToken.jwtToken;

    const body = {
      cognito_username: user.username,
    };

    const request = {
      body,
      headers: {
        Authorization: jwtToken,
      },
    };

    return API.post("clientLoggingAPI", "/check-acceptance", request)
      .then((response) => {
          //console.log("REST API - user event (success):", response);
        return response;
      })
      .catch((error) => {
          //console.error("REST API - user event (error):", error);
        return error;
      });
  } catch (error) {
      //console.error("REST API - user event (error):", error);
  }
}

export async function fetchSourceDocPdf(sourceDocId, pageNumber = null, x1 = null, y1 = null, x2 = null, y2 = null) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.idToken.jwtToken;

    const body = {
      source_doc_id: sourceDocId,
      page_number: pageNumber,
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2
    };

    const request = {
      body,
      headers: {
        Authorization: jwtToken,
      },
    };

    return API.post("clientLoggingAPI", "/fetch-source-doc-pdf", request)
      .then((response) => {
        const { presigned_url } = response;
        return presigned_url;  // Return pre-signed URL for rendering
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
        return error;
      });
  } catch (error) {
    console.error("Error in fetchPDFDocument:", error);
  }
}
