import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material'; 
import "./SourceDocumentTranscript.css";
import TranscriptionBlocks from '../../../../Transcripts/Detail/TranscriptionBlocks.js';
import { useAppContext } from '../../../../Main/AppContext';

function SourceDocumentTranscript() {
    const detailPaneRef = useRef(null);
    const { keTranscriptId, utteranceNumList } = useAppContext();

    useEffect(() => {
        if (keTranscriptId && detailPaneRef.current) {
          detailPaneRef.current.scrollTop = 0;
        }
      }, [keTranscriptId]);

    return (
        <Box ref={detailPaneRef} className="source-document-transcript-pane">
            <TranscriptionBlocks
              ke_transcript_id={keTranscriptId}
              utterance_num_list={utteranceNumList}
            />
        </Box>
    );
}

export default SourceDocumentTranscript;