import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { Auth, Hub } from "aws-amplify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/Main/LandingPage";
import Welcome from "./components/Main/Welcome";
import Transcripts from "./components/Transcripts/Transcripts";
import Company from "./components/Company/Company";
import UserProfile from "./components/User/UserProfile";
import SignIn from "./components/User/SignIn";
import SignUp from "./components/User/SignUp";
import ResponsiveAppBar from "./components/Main/ResponsiveAppBar";
import UserContext from "./components/User/UserContext";
import ConfirmSignUp from "./components/User/ConfirmSignUp";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import Terms from "./components/Terms/Terms";
import Disclaimers from "./components/Terms/Disclaimers";
import Privacy from "./components/Terms/Privacy";
import { ThemeProvider } from '@mui/material/styles';
import KETheme from "./components/Main/KETheme";
import Footer from "./components/Main/Footer";
import Analytics from "./components/Analytics/Analytics";
import PDFViewerTest from "./components/SourceDocs/pdfviewer_test";
import { checkUserAcceptance } from './components/User/UserFunctions';
import { AppProvider } from './components/Main/AppContext';

function App() {
  const [user, setUser] = useState(null);
  const searchInputRef = useRef(null);
  const [email, setEmail] = useState('');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case "signIn":
          checkUser();
          break;
        case "signOut":
          setUser(null);
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", listener);
    checkUser();

    return () => Hub.remove("auth", listener);
  }, []);

  useEffect(() => {
    // Check if the ref is defined and then focus it
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  async function checkUser() {
    setLoading(true); 
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      const userAcceptanceResult = await checkUserAcceptance();
      setHasAcceptedTerms(userAcceptanceResult);
    } catch {
      setUser(null);
      setHasAcceptedTerms(false);
    }
    setLoading(false);
  }

  const contextValue = {
    user,
    email,
    setEmail,
    hasAcceptedTerms,
    setHasAcceptedTerms
  };

  return (
    <ThemeProvider theme={KETheme}>
    <UserContext.Provider value={contextValue}>
      <AppProvider>
        <Router>
          <ResponsiveAppBar user={user} searchInputRef={searchInputRef}/>
          <div className="content-container">
          {!loading && ( 
            <Routes>
              <Route path="/" element={!user ? <LandingPage /> : !hasAcceptedTerms ? <Terms redirectTo="/"/> : <Welcome />} />
              <Route path="/welcome" element={!user ? <LandingPage /> : !hasAcceptedTerms ? <Terms redirectTo="/welcome"/> : <Welcome />} />
              <Route path="/landing" element={!user ? <LandingPage /> : !hasAcceptedTerms ? <Terms redirectTo="/landing"/> : <Welcome />} />
              <Route path="/signin" element={!user ? <SignIn onSignIn={checkUser} /> : !hasAcceptedTerms ? <Terms redirectTo="/signin"/> : <Welcome />} />
              <Route path="/signup" element={!user ? <SignUp onSignUp={checkUser} /> : !hasAcceptedTerms ? <Terms redirectTo="/signup"/> : <Welcome />} />
              <Route path="/verification" element={<ConfirmSignUp />} />
              <Route path="/transcripts" element={!user ? <SignIn onSignIn={checkUser} /> : !hasAcceptedTerms ? <Terms redirectTo="/transcripts"/> : <Transcripts />} />
              <Route path="/company" element={!user ? <SignIn onSignIn={checkUser} /> : !hasAcceptedTerms ? <Terms redirectTo="/company"/> : <Company />} />
              <Route path="/analytics" element={!user ? <SignIn onSignIn={checkUser} /> : !hasAcceptedTerms ? <Terms redirectTo="/analytics"/> : <Analytics />} />
              <Route path="/profile" element={user ? <UserProfile /> : <SignIn onSignIn={checkUser} />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/terms" element={<Terms redirectTo="/welcome"/>} />
              <Route path="/disclaimers" element={<Disclaimers />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/pdfviewer_test" element={<PDFViewerTest />} />
              {/* ... other routes */}
            </Routes>
            )}
          </div>
        <Footer />
        </Router>
      </AppProvider>
    </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
