import React, { useState, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from '@mui/material';
import "./EventList.css";
import { useAppContext } from '../../../../components/Main/AppContext';
import { API, graphqlOperation } from 'aws-amplify';
import { listCompanyEventsForSelectedCompany } from '../../../../graphql_ap/queries';

// Utility functions to extract date and time
function extractDateFromUTC(dateString) {
  const date = new Date(dateString);
  return `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
}

function extractTimeFromUTC(dateString) {
  const date = new Date(dateString);
  const options = {
    timeZone: "America/New_York",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return date.toLocaleTimeString("en-US", options);
}

const EventList = () => {
  const { 
    companyId, 
    setCompanyEventId, 
    setKeTranscriptId, 
    setEventTitle, 
    setEventType, 
    setCompanyEventSourceDocList,
    setSourceDocId
  } = useAppContext();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchCompanyEvents = async () => {
      if (companyId) {
        try {
          console.log("Fetching company events for company ID:", companyId);
          const eventData = await API.graphql(
            graphqlOperation(listCompanyEventsForSelectedCompany, { company_id: companyId })
          );
          const companyEvents = eventData.data.listCompanyEventsForSelectedCompany;
          setEvents(companyEvents);
        } catch (error) {
          console.error("Error fetching company events:", error);
        }
      }
    };

    fetchCompanyEvents();
  }, [companyId]);

  // Columns for the DataGrid
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      valueGetter: (params) => extractDateFromUTC(params.row.event_utc),
    },
    {
      field: 'time',
      headerName: 'Time (ET)',
      flex: 1,
      valueGetter: (params) => extractTimeFromUTC(params.row.event_utc),
    },
    {
      field: 'event_title',
      headerName: 'Event',
      flex: 1,
    },
  ];

  const handleRowClick = (params) => {
    setCompanyEventId(params.row.company_event_id);
    setKeTranscriptId(params.row.ke_transcript_id || null);
    setEventTitle(params.row.event_title);
    setEventType(params.row.event_type);
    
    // Set the companyEventSourceDocList
    const sourceDocuments = params.row.sourceDocuments || [];
    setCompanyEventSourceDocList(sourceDocuments.map(doc => ({
      source_doc_id: doc.source_doc_id,
      source_doc_type: doc.source_doc_type,
      display_name: getDisplayName(doc.source_doc_type),
      icon_type: getIconType(doc.source_doc_type)
    })));
    
    // Reset the sourceDocId
    setSourceDocId(null);
  };

  // Helper functions to get display name and icon type
  const getDisplayName = (sourceDocType) => {
    const displayNames = {
      "ke_transcript": "Transcript",
      "earnings_release_pdf": "Press Release",
      "earnings_presentation_pdf": "Presentation",
      "ir_supplement_pdf": "Supplement",
      "transcript_factset_pdf": "Transcript (pdf)"
    };
    return displayNames[sourceDocType] || "Other";
  };

  const getIconType = (sourceDocType) => {
    const iconTypes = {
      "ke_transcript": "word",
      "earnings_release_pdf": "pdf",
      "earnings_presentation_pdf": "pdf",
      "ir_supplement_pdf": "pdf",
      "transcript_factset_pdf": "pdf"
    };
    return iconTypes[sourceDocType] || "pdf";
  };

  return (
    <Box className="event-list-box">
      <DataGrid
        className="event-list-grid"
        rows={events}
        columns={columns}
        getRowId={(row) => row.company_event_id}
        hideFooter
        rowHeight={30}
        disableExtendRowFullWidth={true}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default EventList;
