import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import SourceDocumentTranscript from "./SourceDocumentTranscript";
import SourceDocumentPDF from "./SourceDocumentPDF";
import { useAppContext } from '../../../../Main/AppContext';
import "./SourceDocument.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`source-document-tabpanel-${index}`}
      aria-labelledby={`source-document-tab-${index}`}
      className="source-document-tab-panel"
      {...other}
      sx={{ p: 1, height: 'calc(100% - 8px)', width: 'calc(100% - 8px)' }}
    >
      {value === index && (
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function SourceDocument() {
    const { 
        sourceDocId, 
        companyMetricValueId, 
        companyMetricDetails, 
        setSourceDocId, 
        setUtteranceNumList, 
        companyEventSourceDocList,
        setCompanyMetricValueId
    } = useAppContext();
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (companyMetricValueId && companyMetricDetails && companyMetricDetails.length > 0) {
            const selectedMetric = companyMetricDetails[0].metrics.find(
                metric => metric.company_metric_value_id === companyMetricValueId
            );

            if (selectedMetric) {
                setSourceDocId({
                    source_doc_id: selectedMetric.source_doc_id,
                    source_doc_type: selectedMetric.source_doc_type
                });

                if (selectedMetric.source_doc_type === "ke_transcript" && selectedMetric.source_location_text_segment_id !== null) {
                    setUtteranceNumList([selectedMetric.source_location_text_segment_id]);
                } else {
                    setUtteranceNumList([]);
                }

                // Set the tab value based on the selected metric's source_doc_type
                const tabIndex = companyEventSourceDocList.findIndex(doc => doc.source_doc_id === selectedMetric.source_doc_id);
                if (tabIndex !== -1) {
                    setTabValue(tabIndex);
                }
            }
        }
    }, [companyMetricValueId, companyMetricDetails, setSourceDocId, setUtteranceNumList, companyEventSourceDocList]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        const selectedDoc = companyEventSourceDocList[newValue];
        setSourceDocId({
            source_doc_id: selectedDoc.source_doc_id,
            source_doc_type: selectedDoc.source_doc_type
        });
        // Clear the selected metric value when switching tabs
        setCompanyMetricValueId(null);
        setUtteranceNumList([]);
    };

    const isPdfType = (type) => {
        const pdfTypes = ["earnings_release_pdf", "earnings_presentation_pdf", "ir_supplement_pdf", "transcript_factset_pdf"];
        return pdfTypes.includes(type);
    };

    return (
        <Box className="source-document-boundary-box" sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}> 
            <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="source document tabs" 
                className="source-document-tabs"
                centered
                sx={{ minHeight: '40px', maxHeight: '40px' }}
            >
                {companyEventSourceDocList.map((doc, index) => (
                    <Tab 
                        key={doc.source_doc_id}
                        label={doc.display_name} 
                        id={`source-document-tab-${index}`} 
                        aria-controls={`source-document-tabpanel-${index}`} 
                    />
                ))}
            </Tabs>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', width: '100%' }}>
                {companyEventSourceDocList.map((doc, index) => (
                    <TabPanel key={doc.source_doc_id} value={tabValue} index={index}>
                        {doc.source_doc_type === "ke_transcript" ? (
                            <SourceDocumentTranscript />
                        ) : isPdfType(doc.source_doc_type) ? (
                            <SourceDocumentPDF />
                        ) : null}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}

export default SourceDocument;