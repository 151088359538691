import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { fetchSourceDocPdf } from '../../../../User/UserFunctions';
import { useAppContext } from '../../../../Main/AppContext';
import "./SourceDocumentPDF.css";

function SourceDocumentPDF() {
    const { sourceDocId, companyMetricValueId, companyMetricDetails } = useAppContext();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const iframeRef = useRef(null);

    useEffect(() => {
        if (sourceDocId) {
            setLoading(true);
            let selectedMetric = null;
            if (companyMetricValueId && companyMetricDetails && companyMetricDetails.length > 0) {
                selectedMetric = companyMetricDetails[0].metrics.find(
                    metric => metric.company_metric_value_id === companyMetricValueId
                );
            }

            fetchSourceDocPdf(
                sourceDocId.source_doc_id,
                selectedMetric?.source_location_pdf_page_number || null,
                selectedMetric?.source_location_pdf_x1 || null,
                selectedMetric?.source_location_pdf_y1 || null,
                selectedMetric?.source_location_pdf_x2 || null,
                selectedMetric?.source_location_pdf_y2 || null
            )
            .then(url => {
                setPdfUrl(url);
                setLoading(false);
            })
            .catch(err => {
                console.error("Error fetching PDF:", err);
                setError("Failed to load PDF");
                setLoading(false);
            });
        }
    }, [sourceDocId, companyMetricValueId, companyMetricDetails]);

    useEffect(() => {
        if (pdfUrl && iframeRef.current) {
            const iframe = iframeRef.current;
            iframe.onload = () => {
                if (companyMetricValueId && companyMetricDetails && companyMetricDetails.length > 0) {
                    const selectedMetric = companyMetricDetails[0].metrics.find(
                        metric => metric.company_metric_value_id === companyMetricValueId
                    );
                    if (selectedMetric && selectedMetric.source_location_pdf_page_number) {
                        // Wait for the PDF to fully load before attempting navigation
                        setTimeout(() => {
                            iframe.contentWindow.postMessage({
                                type: 'pdf-go-to-page',
                                page: selectedMetric.source_location_pdf_page_number
                            }, '*');
                        }, 1000); // Adjust this delay if needed
                    }
                }
            };
        }
    }, [pdfUrl, companyMetricValueId, companyMetricDetails]);

    // Listen for messages from the iframe
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data && event.data.type === 'pdf-loaded') {
                // PDF is fully loaded, now we can navigate
                if (companyMetricValueId && companyMetricDetails && companyMetricDetails.length > 0) {
                    const selectedMetric = companyMetricDetails[0].metrics.find(
                        metric => metric.company_metric_value_id === companyMetricValueId
                    );
                    if (selectedMetric && selectedMetric.source_location_pdf_page_number) {
                        iframeRef.current.contentWindow.postMessage({
                            type: 'pdf-go-to-page',
                            page: selectedMetric.source_location_pdf_page_number
                        }, '*');
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [companyMetricValueId, companyMetricDetails]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box height="100%" width="100%"> 
            {pdfUrl ? (
                <iframe
                    ref={iframeRef}
                    src={`${pdfUrl}#page=${companyMetricDetails?.[0]?.metrics.find(m => m.company_metric_value_id === companyMetricValueId)?.source_location_pdf_page_number || 1}`}
                    title="Source Document PDF"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                />
            ) : (
                <Typography>No PDF available</Typography>
            )}
        </Box>
    );
}

export default SourceDocumentPDF;