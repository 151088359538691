import React, { useState, useEffect } from 'react';
import { Box, InputBase, Paper, List, ListItem, ListItemText, ListSubheader, Typography, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ClearIcon from '@mui/icons-material/Clear'; 
import { API, graphqlOperation } from "aws-amplify";
import { listCompanies, listCompanyFavorites } from "../../graphql_ap/queries";
import './Search.css';

function Search({ setCompanyId }) {
  const [query, setQuery] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [companies, setCompanies] = useState([]);
  const [favorites, setFavorites] = useState({});
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesData = await API.graphql(graphqlOperation(listCompanies));
        const companiesList = companiesData.data.listCompanies;
        
        const favoritesData = await API.graphql(graphqlOperation(listCompanyFavorites));
        const favoriteCompanies = favoritesData.data.listCompanyFavorites.map(fav => fav.company_id);

        setCompanies(companiesList);

        const favoriteDict = favoriteCompanies.reduce((acc, companyId) => {
          acc[companyId] = true;
          return acc;
        }, {});
        setFavorites(favoriteDict);
      } catch (error) {
        console.error("Error fetching companies and favorites:", error);
      }
    };

    fetchData();
  }, []);

  const handleFavoriteToggle = (companyId) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [companyId]: !prevFavorites[companyId],
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, filteredCompanies.length - 1));
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      const selectedCompany = filteredCompanies[selectedIndex];
      handleCompanySelection(selectedCompany);
    } else if (e.key === 'Escape') {
      setQuery('');
    }
  };

  const handleCompanySelection = (company) => {
    setQuery('');
    setCompanyId(company.company_id);
    navigate('/company', {
      state: {
        company_id: company.company_id,
        company_name: company.company_name,
        primary_exchange: company.primary_exchange,
        primary_ticker: company.primary_ticker
      }
    });
  };

  const handleClear = () => {
    setQuery('');
  };

  const filteredCompanies = companies.filter(company =>
    company.primary_ticker.toLowerCase().includes(query.toLowerCase()) ||
    company.company_name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Box 
      className="search-container" 
      sx={{
        borderColor: isActive ? theme.palette.ke.link_hover : theme.palette.ke.search_border_unselected,
        '&:focus-within': {
          borderColor: theme.palette.ke.link_hover,
        },
        position: 'relative',
      }}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
    >
      <SearchIcon className="search-icon" />
      <InputBase
        placeholder="Company…"
        inputProps={{ 'aria-label': 'search' }}
        className="search-input"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{
          color: 'white',
          fontSize: '14px',
        }}
      />
      {query && (
        <IconButton onClick={handleClear} className="clear-icon" aria-label="clear search">
          <ClearIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      {query && (
        <Paper className="search-dropdown" sx={{ backgroundColor: theme.palette.ke.dark_blue }}>
          <List>
            <ListSubheader
              className="dropdown-subheader"
              sx={{
                color: 'white',
                backgroundColor: theme.palette.ke.dark_blue,
                borderBottom: `1px solid ${theme.palette.ke.search_border_unselected}`,
                padding: '4px 8px',
              }}
            >
              COMPANIES
            </ListSubheader>
            {filteredCompanies.map((company, index) => (
              <ListItem
                key={company.company_id}
                className="dropdown-item"
                selected={selectedIndex === index}
                onMouseEnter={() => setSelectedIndex(index)}
                sx={{
                  backgroundColor: selectedIndex === index ? theme.palette.ke.link_hover : 'transparent',
                  color: 'white',
                  padding: '4px 8px',
                  '&:hover': {
                    backgroundColor: theme.palette.ke.link_hover,
                  },
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.ke.link_hover} !important`,
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: `${theme.palette.ke.link_hover} !important`,
                  },
                }}
                onClick={() => handleCompanySelection(company)}
              >
                <IconButton onClick={(e) => { e.stopPropagation(); handleFavoriteToggle(company.company_id); }}>
                  {favorites[company.company_id] ? <StarIcon sx={{ color: theme.palette.ke.star }} /> : <StarBorderIcon sx={{ color: theme.palette.ke.star }} />}
                </IconButton>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="span">
                      {highlightText(company.primary_ticker, query)} - {highlightText(company.company_name, query)}
                    </Typography>
                  }
                  className="dropdown-text"
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

function highlightText(text, query) {
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
  );
}

export default Search;
